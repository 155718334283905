.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
  //new 19/ene/2023
  @media (min-width: 1600px) {
    width: 100%;
    max-width: 1920px;
  }

  @media screen and (min-width: 1920px) {
    width: 100%;
    max-width: 2560px;
  }

  @media screen and (min-width: 2560px) {
    width: 100%;
    max-width: 3440px;
  }

  @media screen and (min-width: 3440px) {
    width: 100%;
    max-width: 3840px;
  }

  @media screen and (min-width: 3840px) {
    width: 100%;
    max-width: 4000px;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 50px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 250px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

.customclass.ant-menu-item.ant-menu-item-selected {
  color: #1b476a; /*Overriden property*/
  border-bottom: 2px solid #1b476a;
}

.customclass.ant-menu-item:hover.ant-menu-item.ant-menu-item-active {
  color: #3879b1;
  border-bottom: 2px solid #3879b1;
}

.customclass.ant-menu-submenu.ant-menu-submenu-open {
  color: #1b476a; /*Overriden property*/
  border-bottom: 2px solid #1b476a;
}

.customclass.ant-menu-submenu-open:hover.ant-menu-submenu {
  color: #3879b1;
  border-bottom: 2px solid #3879b1;
}

#formCust > div{margin-bottom: 0}

.panel__title .subhead {
    text-transform: none;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
}


.ant-menu.ant-menu-light.ant-menu-root.ant-menu-horizontal{border-bottom: 0 !important;}

.overlayPBIreport {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 65px);
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.overlayPBIreport::before {
  content: 'Podrá analizar este reporte en la versión de pago';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);// rotate(-45deg);
  color: rgba(0, 0, 0, 0.5);
  font-size: 3rem;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat;
}