.success{
    background-color: #3879b1;
    color:black;
}

.error {
    background-color: #ff4861;
    color:black;
}

.warning{
    background-color: #f6da6e;
    color:black;
}

.info{
   background-color: #70BBFD;
    color:black;
}

.ant-notification-notice-message{
    color:black;
}


.anticon.ant-notification-notice-icon-success{
    color: white;
    margin-top: 3%;
}

.anticon.ant-notification-notice-icon-error{
    color: white;
    margin-top: 3%;
}

.anticon.ant-notification-notice-icon-warning{
    color: white;
    margin-top: 3%;
}


.anticon.ant-notification-notice-icon-info{
    color: white;
    margin-top: 3%;
}
