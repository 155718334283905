p {
    margin-bottom: 0;
    margin-top: 0;
}

.ant-modal-body{
    padding: 10px 24px;
}

.fontSize__table {
    font-size: 12px;
}
.fontSize__description-view {
    font-size: 16px !important;
    background: #0066ff;
    color: #fff;
}
/* .loca2 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
} */

.ant-descriptions-view > table {
    text-align: end;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label, .ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content{
    padding: 8px 8px;
    font-size: 12px;   
}